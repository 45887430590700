/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotografie - exterier"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-i1y1bt pb--60 pt--80" name={"uvod-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/5bf34fb3365b48ec9116b9cd7d075953_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Fotografie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--16" name={"menu"} style={{"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--18 w--500 ls--02" content={"<a href=\"/fotografie\" style=\"color: var(--color-blend--15);\">Interiér</a>&nbsp;<span style=\"color: var(--color-blend--50);\"> |&nbsp;</span> <a href=\"/fotografie-exterier\" style=\"color: rgb(252, 113, 13);\">Exteriér</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rg5cicnmvp9"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/25609/1eb67e944a5042f2bf272f1f853ff202_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25609/1eb67e944a5042f2bf272f1f853ff202_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/1eb67e944a5042f2bf272f1f853ff202_s=660x_.JPG 660w, https://cdn.swbpg.com/t/25609/1eb67e944a5042f2bf272f1f853ff202_s=860x_.JPG 860w, https://cdn.swbpg.com/t/25609/1eb67e944a5042f2bf272f1f853ff202_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Zahrada s herními prvky pro děti"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=660x_.JPG 660w, https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=860x_.JPG 860w, https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/25609/7d4730d7a3754ef8bdf9f0eb9a778621_s=2000x_.JPG 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Krytá teras a vstup do sklepa"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/25609/b2124f65135c4e7dadfc275566626648_s=660x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25609/b2124f65135c4e7dadfc275566626648_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/b2124f65135c4e7dadfc275566626648_s=660x_.JPG 660w, https://cdn.swbpg.com/t/25609/b2124f65135c4e7dadfc275566626648_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Zahrada s herními prvky pro děti"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/25609/ab3272009059404788b1fe2aad72d7c1_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25609/ab3272009059404788b1fe2aad72d7c1_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/ab3272009059404788b1fe2aad72d7c1_s=660x_.JPG 660w, https://cdn.swbpg.com/t/25609/ab3272009059404788b1fe2aad72d7c1_s=860x_.JPG 860w, https://cdn.swbpg.com/t/25609/ab3272009059404788b1fe2aad72d7c1_s=1400x_.JPG 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Zahrada s herními prvky pro děti"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"0898e80032cj"} style={{"paddingTop":46}} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/ff8aab4aed2b491a9bdb007cebdcbf00_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Terasa"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Ložnice B - dvojlůžko (manželská postel)"} src={"https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/de79119575e142399a261a2314b287f6_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Zadní zahrada s ohništěm"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/73187a8a9d814125b8ccdadca4fe275b_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Přední zahrada s parkovištěm"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/340318a6aefa4e61a8653271a1c710d6_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Krytá terasa a sezení pro 8 osob"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/8447db6de46d43829406aad3d5c5c2b6_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Krytá terasa a sezení pro 8 osob"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/e88ab3c7e635438bb4a3868e69d76e8c_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Pohled z terasy na zadní zahradu"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/b15d180d2f3b4e5bbd62d51679196e5a_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Terasa"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/b224bac400254b6aae91bbc3ba9f0955_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/b224bac400254b6aae91bbc3ba9f0955_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/b224bac400254b6aae91bbc3ba9f0955_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/b224bac400254b6aae91bbc3ba9f0955_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/b224bac400254b6aae91bbc3ba9f0955_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Terasa"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/cdc30195750349e68941f08bedaee6a9_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/cdc30195750349e68941f08bedaee6a9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/cdc30195750349e68941f08bedaee6a9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/cdc30195750349e68941f08bedaee6a9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/cdc30195750349e68941f08bedaee6a9_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Vchod do chalupy"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25609/809377a6998e45d3b5728173ef38b143_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Příjezdová cesta, parkoviště a přední zahrada"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}